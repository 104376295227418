@font-face {
  font-family: "Modelo";
  src: url("./fonts/Modelo-Regular.woff2") format("woff2"), url("./fonts/Modelo-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Modelo";
  src: url("./fonts/Modelo-Bold.woff2") format("woff2"), url("./fonts/Modelo-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Modelo";
  src: url("./fonts/Modelo-Light.woff2") format("woff2"), url("./fonts/Modelo-Light.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
}

:root {
  --font-family: "Modelo", Helvetica, Arial, sans-serif;
  --font-h1: bold 2.7em/1.3em var(--font-family);
  --font-h2: bold 1.3em/1.3em var(--font-family);
  --font-text: normal normal 1.2em/1.3em var(--font-family);
  // --groove-border: groove 3px rgba(0, 0, 0, 0.4);
  --groove-border: groove 3px white;
}

@keyframes spokenWord {
  from {
    color: blue;
    font-weight: bold;
  }
  to {
    color: inherit;
    font-weight: inherit;
  }
}

@keyframes voice {
  from {
    background: repeating-radial-gradient(#00d4ff, #090979 31%);
    box-shadow: 0 0 30px 15px rgba(0, 212, 255, 0.7);
  }
  to {
    background: repeating-radial-gradient(#00d4ff, #090979 32%);
    box-shadow: 0 0 30px 15px rgba(0, 212, 255, 1);
  }
}

// ================
// Prefered Default settings

::selection {
  color: white;
  background: black;
}

html {
  box-sizing: border-box;
}

body {
  margin: 0;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

// ================
// Styles
body {
  font: normal 1rem var(--font-family);
  // background: rgb(50, 24, 196);
  background: rgb(219, 98, 105);
}

#app {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  &[data-session="start"] {
    #board {
      transform: scale(1);
    }
  }

  &[data-session="active"],
  &[data-session="summary"] {
    #overlay {
      display: none;
    }

    #board {
      transform: perspective(1000px) rotate3D(10, -2, 2, 30deg) scale(0.8) translateY(-300px);
    }

    #app-header {
      article {
        display: none;
      }
    }
  }

  &[data-session="summary"] {
    #receipt {
      right: 0;
    }

    #board {
      pointer-events: none;
    }
  }
}

#overlay {
  background: rgba(219, 98, 105, 0.97);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;

  button {
    border: 0;
    border-radius: 5px;
    padding: 20px 20px 15px 20px;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.6);
    font: var(--font-h1);
    transition: all 0.2s ease-out;
    cursor: pointer;
    background: white;

    &:hover {
      background: rgb(230, 230, 230);
    }

    &:active {
      background: rgb(200, 200, 200);
      box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
    }
  }
}

#restart-button {
  position: fixed;
  top: 5px;
  right: 5px;
  // background: rgb(219, 219, 219);
  font: var(--font-text);
  border: none;
  border-radius: 2px;
  padding: 5px 15px 2px 15px;
  z-index: 20;
  cursor: pointer;
  background: white;
  &:hover {
    background: rgb(230, 230, 230);
  }
}

#app-header {
  z-index: 100;
  color: white;
  background: black;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;

  @media screen and (min-width: 1023px) {
    width: auto;
    min-height: 0;

    #small-screen-msg {
      display: none;
    }
  }
  
  #small-screen-msg {
    color: red;
  }

  h1 {
    margin: 0;
    font: var(--font-h2);
  }
  p {
    width: 300px;
    font: var(--font-text);
  }
  span {
    text-decoration: underline;
  }
  a {
    color: white;
    text-decoration: none;
  }
  img {
    width: 145px;
    height: 70px;
    margin: 0 auto;
    filter: grayscale(1);
    transition: filter 0.2s ease-out;
    object-fit: cover;
    border: solid 2px rgba(255, 255, 255, 0.8);

    &:hover {
      filter: grayscale(0);
    }
  }
}

#receipt {
  background: black;
  padding: 30px 50px;
  color: white;
  position: fixed;
  z-index: 100;
  top: 150px;
  right: -450px;
  width: 450px;
  transition: all 0.2s ease-out;

  table {
    tfoot {
      tr {
        font-weight: bold;
        color: red;
      }
    }
  }
}

#board {
  width: 1300px;
  position: relative;
  transition: transform 1s ease-out;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 50px 50px rgba(0, 0, 0, 0.2);
}

.panel-group {
  background: rgb(245, 245, 245);
  display: flex;
  justify-content: space-between;
  gap: 0px;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
  position: relative;

  &#top-group {
    transition: transform 1s ease-out;
    transform: perspective(1000px) rotate3D(-1, 0, 0, 22deg) translateZ(56px) translateY(-11px);
    height: 300px;
    padding: 0 40px;
    align-items: center;
  }

  &#top-3d,
  &#front-3d {
    background: rgb(136, 150, 156);
    transition: transform 1s ease-out;
  }
  &#top-3d {
    transform: perspective(1000px) rotate3D(1, 0, 0, 45deg) translateZ(65px) translateY(75px);
    height: 40px;
  }
  &#front-3d {
    transform: perspective(1000px) rotate3D(-1, 0, 0, 45deg) translateZ(-15px) translateY(8px);
    height: 40px;
  }
}

.panel {
  flex: 1 1 auto;
  width: 350px;
  background: rgb(235, 235, 235);
  border-right: var(--groove-border);
  border-bottom: var(--groove-border);
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;

  h1 {
    font: var(--font-h1);
    text-align: center;
    margin: 30px 0 20px 0;
    text-transform: uppercase;
    color: rgb(219, 219, 219);
    text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 0 rgba(255, 255, 255, 1);
  }

  h2 {
    font: var(--font-h2);
    margin: 0 0 10px 0;
  }

  .panel__prop {
    display: flex;
    flex: 1 1 auto;
    transition: background 0.2s ease-out;
    border-top: var(--groove-border);

    &:hover {
      background: rgba(0, 0, 0, 0.04);
    }

    .panel__prop__state {
      --color: rgb(250, 110, 110);
      width: 25px;
      height: 30px;
      margin: 20px 0 0 20px;
      background: var(--color);
      border: solid 1px rgba(0, 0, 0, 0.15);
      border-radius: 3px;
      box-shadow: 0 0 3px var(--color);
      transition: background 0.1s ease-out;
    }

    .panel__prop__input {
      width: 100%;
      margin: 20px 20px;

      label {
        display: inline-block;
        margin-bottom: 5px;
        font: var(--font-text);
      }

      input[type="text"],
      input[type="tel"] {
        width: 100%;
        height: 35px;
        padding: 0 10px;
        border: inset 2px rgba(0, 0, 0, 0.4);
        border-radius: 4px;
        background: rgb(209, 218, 221);
        box-shadow: inset 0 0 3px rgb(168, 183, 189);
        color: rgb(136, 150, 156);
        outline: none;
        font: var(--font-text);
      }

      input[type="range"] {
        width: 100%;
      }

      output {
        float: right;
        font: var(--font-text);
      }
    }

    .panel__prop__reset {
      display: flex;
      align-items: center;
      border-left: var(--groove-border);
      padding: 0 10px;

      button {
        background: rgba(0, 0, 0, 0.05);
        height: 30px;
        width: 30px;
        border-left: var(--groove-border);
        border-radius: 30px;
      }
    }

    &[data-state="set"] .panel__prop__state {
      --color: greenyellow;
    }
  }
}

ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

#assistant {
  height: 70%;
  display: flex;

  article {
    width: 400px;
    padding: 30px;
    height: 100%;
    overflow-y: scroll;
    display: flex;
    flex-direction: column-reverse;
    border: inset 2px rgba(0, 0, 0, 0.4);
    border-radius: 4px;
    background: rgb(209, 218, 221);
    box-shadow: inset 0 0 3px rgb(168, 183, 189);
    color: rgb(136, 150, 156);
    font: var(--font-text);
  }

  p {
    margin-top: 0;
  }

  p:first-child span:last-child {
    animation: spokenWord 0.5s linear;
  }
}

#speech-visual {
  display: flex;
  justify-content: center;
  align-items: center;
  border: groove 15px rgba(175, 132, 132, 0.7);
  border-radius: 9999px;

  #voice {
    height: 180px;
    width: 120px;
    border-radius: 9999px;
    border: solid 2px rgb(86, 94, 97);
    background: repeating-radial-gradient(#00d4ff, #090979 31%);
    box-shadow: 0 0 30px 15px rgba(0, 212, 255, 0.7);
    transition: all 2s ease-out;

    &[data-speaking] {
      animation: voice 0.2s linear;
    }
  }
}

#price {
  display: none;
  output {
    font: var(--font-h2);
  }
}

#close-session {
  h1 {
    text-align: center;
  }
  .closing-buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 130px;
    border: var(--groove-border);
    border-radius: 20px;
    overflow: hidden;
  }

  .close-btn {
    display: flex;
    align-items: center;
    width: 200px;
    height: 100%;
    font: var(--font-text);
    font-size: 1.5em;
    transition: all 0.2s ease-out;
    background: rgb(202, 202, 202);

    &:first-child {
      border-right: var(--groove-border);
    }

    img {
      flex: 0 0 auto;
      width: 70px;
      margin: 5px 15px 5px 5px;
    }

    &:hover {
      background: rgb(250, 110, 110);
      font-weight: bold;
    }
  }
}
