@font-face {
  font-family: "Modelo";
  src: url("Modelo-Regular.24ab7ea2.woff2") format("woff2"), url("Modelo-Regular.746bac60.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Modelo";
  src: url("Modelo-Bold.e0c2d730.woff2") format("woff2"), url("Modelo-Bold.f211c72a.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Modelo";
  src: url("Modelo-Light.6f95b35d.woff2") format("woff2"), url("Modelo-Light.1c8e033d.woff") format("woff");
  font-weight: lighter;
  font-style: normal;
}
:root {
  --font-family: "Modelo", Helvetica, Arial, sans-serif;
  --font-h1: bold 2.7em/1.3em var(--font-family);
  --font-h2: bold 1.3em/1.3em var(--font-family);
  --font-text: normal normal 1.2em/1.3em var(--font-family);
  --groove-border: groove 3px white;
}
@keyframes spokenWord {
  from {
    color: blue;
    font-weight: bold;
  }
  to {
    color: inherit;
    font-weight: inherit;
  }
}
@keyframes voice {
  from {
    background: repeating-radial-gradient(#00d4ff, #090979 31%);
    box-shadow: 0 0 30px 15px rgba(0, 212, 255, 0.7);
  }
  to {
    background: repeating-radial-gradient(#00d4ff, #090979 32%);
    box-shadow: 0 0 30px 15px #00d4ff;
  }
}
::selection {
  color: white;
  background: black;
}
html {
  box-sizing: border-box;
}
body {
  margin: 0;
}
*,
*:before,
*:after {
  box-sizing: inherit;
}
body {
  font: normal 1rem var(--font-family);
  background: #db6269;
}
#app {
  height: 100vh;
  overflow: hidden;
  display: flex;
  align-items: flex-start;
  justify-content: center;
}
#app[data-session="start"] #board {
  transform: scale(1);
}
#app[data-session="active"] #overlay,
#app[data-session="summary"] #overlay {
  display: none;
}
#app[data-session="active"] #board,
#app[data-session="summary"] #board {
  transform: perspective(1000px) rotate3D(10, -2, 2, 30deg) scale(0.8) translateY(-300px);
}
#app[data-session="active"] #app-header article,
#app[data-session="summary"] #app-header article {
  display: none;
}
#app[data-session="summary"] #receipt {
  right: 0;
}
#app[data-session="summary"] #board {
  pointer-events: none;
}
#overlay {
  background: rgba(219, 98, 105, 0.97);
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
}
#overlay button {
  border: 0;
  border-radius: 5px;
  padding: 20px 20px 15px 20px;
  box-shadow: 3px 3px 10px rgba(0, 0, 0, 0.6);
  font: var(--font-h1);
  transition: all 0.2s ease-out;
  cursor: pointer;
  background: white;
}
#overlay button:hover {
  background: #e6e6e6;
}
#overlay button:active {
  background: #c8c8c8;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.6);
}
#restart-button {
  position: fixed;
  top: 5px;
  right: 5px;
  font: var(--font-text);
  border: none;
  border-radius: 2px;
  padding: 5px 15px 2px 15px;
  z-index: 20;
  cursor: pointer;
  background: white;
}
#restart-button:hover {
  background: #e6e6e6;
}
#app-header {
  z-index: 100;
  color: white;
  background: black;
  padding: 20px;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  min-height: 100vh;
}
@media screen and (min-width: 1023px) {
  #app-header {
    width: auto;
    min-height: 0;
  }
  #app-header #small-screen-msg {
    display: none;
  }
}
#app-header #small-screen-msg {
  color: red;
}
#app-header h1 {
  margin: 0;
  font: var(--font-h2);
}
#app-header p {
  width: 300px;
  font: var(--font-text);
}
#app-header span {
  text-decoration: underline;
}
#app-header a {
  color: white;
  text-decoration: none;
}
#app-header img {
  width: 145px;
  height: 70px;
  margin: 0 auto;
  filter: grayscale(1);
  transition: filter 0.2s ease-out;
  object-fit: cover;
  border: solid 2px rgba(255, 255, 255, 0.8);
}
#app-header img:hover {
  filter: grayscale(0);
}
#receipt {
  background: black;
  padding: 30px 50px;
  color: white;
  position: fixed;
  z-index: 100;
  top: 150px;
  right: -450px;
  width: 450px;
  transition: all 0.2s ease-out;
}
#receipt table tfoot tr {
  font-weight: bold;
  color: red;
}
#board {
  width: 1300px;
  position: relative;
  transition: transform 1s ease-out;
  background: rgba(0, 0, 0, 0.2);
  box-shadow: 5px 5px 50px 50px rgba(0, 0, 0, 0.2);
}
.panel-group {
  background: #f5f5f5;
  display: flex;
  justify-content: space-between;
  gap: 0px;
  border-radius: 2px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.6);
  position: relative;
}
.panel-group#top-group {
  transition: transform 1s ease-out;
  transform: perspective(1000px) rotate3D(-1, 0, 0, 22deg) translateZ(56px) translateY(-11px);
  height: 300px;
  padding: 0 40px;
  align-items: center;
}
.panel-group#top-3d,
.panel-group#front-3d {
  background: #88969c;
  transition: transform 1s ease-out;
}
.panel-group#top-3d {
  transform: perspective(1000px) rotate3D(1, 0, 0, 45deg) translateZ(65px) translateY(75px);
  height: 40px;
}
.panel-group#front-3d {
  transform: perspective(1000px) rotate3D(-1, 0, 0, 45deg) translateZ(-15px) translateY(8px);
  height: 40px;
}
.panel {
  flex: 1 1 auto;
  width: 350px;
  background: #ebebeb;
  border-right: var(--groove-border);
  border-bottom: var(--groove-border);
  color: rgba(0, 0, 0, 0.6);
  display: flex;
  flex-direction: column;
}
.panel h1 {
  font: var(--font-h1);
  text-align: center;
  margin: 30px 0 20px 0;
  text-transform: uppercase;
  color: #dbdbdb;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2), 0 1px 0 #ffffff;
}
.panel h2 {
  font: var(--font-h2);
  margin: 0 0 10px 0;
}
.panel .panel__prop {
  display: flex;
  flex: 1 1 auto;
  transition: background 0.2s ease-out;
  border-top: var(--groove-border);
}
.panel .panel__prop:hover {
  background: rgba(0, 0, 0, 0.04);
}
.panel .panel__prop .panel__prop__state {
  --color: #fa6e6e;
  width: 25px;
  height: 30px;
  margin: 20px 0 0 20px;
  background: var(--color);
  border: solid 1px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  box-shadow: 0 0 3px var(--color);
  transition: background 0.1s ease-out;
}
.panel .panel__prop .panel__prop__input {
  width: 100%;
  margin: 20px 20px;
}
.panel .panel__prop .panel__prop__input label {
  display: inline-block;
  margin-bottom: 5px;
  font: var(--font-text);
}
.panel .panel__prop .panel__prop__input input[type="text"],
.panel .panel__prop .panel__prop__input input[type="tel"] {
  width: 100%;
  height: 35px;
  padding: 0 10px;
  border: inset 2px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  background: #d1dadd;
  box-shadow: inset 0 0 3px #a8b7bd;
  color: #88969c;
  outline: none;
  font: var(--font-text);
}
.panel .panel__prop .panel__prop__input input[type="range"] {
  width: 100%;
}
.panel .panel__prop .panel__prop__input output {
  float: right;
  font: var(--font-text);
}
.panel .panel__prop .panel__prop__reset {
  display: flex;
  align-items: center;
  border-left: var(--groove-border);
  padding: 0 10px;
}
.panel .panel__prop .panel__prop__reset button {
  background: rgba(0, 0, 0, 0.05);
  height: 30px;
  width: 30px;
  border-left: var(--groove-border);
  border-radius: 30px;
}
.panel .panel__prop[data-state="set"] .panel__prop__state {
  --color: greenyellow;
}
ul {
  padding: 0;
  margin: 0;
  list-style-type: none;
}
#assistant {
  height: 70%;
  display: flex;
}
#assistant article {
  width: 400px;
  padding: 30px;
  height: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column-reverse;
  border: inset 2px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  background: #d1dadd;
  box-shadow: inset 0 0 3px #a8b7bd;
  color: #88969c;
  font: var(--font-text);
}
#assistant p {
  margin-top: 0;
}
#assistant p:first-child span:last-child {
  animation: spokenWord 0.5s linear;
}
#speech-visual {
  display: flex;
  justify-content: center;
  align-items: center;
  border: groove 15px rgba(175, 132, 132, 0.7);
  border-radius: 9999px;
}
#speech-visual #voice {
  height: 180px;
  width: 120px;
  border-radius: 9999px;
  border: solid 2px #565e61;
  background: repeating-radial-gradient(#00d4ff, #090979 31%);
  box-shadow: 0 0 30px 15px rgba(0, 212, 255, 0.7);
  transition: all 2s ease-out;
}
#speech-visual #voice[data-speaking] {
  animation: voice 0.2s linear;
}
#price {
  display: none;
}
#price output {
  font: var(--font-h2);
}
#close-session h1 {
  text-align: center;
}
#close-session .closing-buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 130px;
  border: var(--groove-border);
  border-radius: 20px;
  overflow: hidden;
}
#close-session .close-btn {
  display: flex;
  align-items: center;
  width: 200px;
  height: 100%;
  font: var(--font-text);
  font-size: 1.5em;
  transition: all 0.2s ease-out;
  background: #cacaca;
}
#close-session .close-btn:first-child {
  border-right: var(--groove-border);
}
#close-session .close-btn img {
  flex: 0 0 auto;
  width: 70px;
  margin: 5px 15px 5px 5px;
}
#close-session .close-btn:hover {
  background: #fa6e6e;
  font-weight: bold;
}

/*# sourceMappingURL=index.9e7f448a.css.map */
